import React from 'react'

const Loading = () => {
  return (
    <div className="progress">
        <div className="indeterminate"></div>
    </div>
  )
}

export default Loading
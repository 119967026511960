import React from 'react'

import "./styles.css"

const Navbar = () => {
  return (
    <nav className='nav'>
      <div className='innav '>
        
      </div>
    </nav>
  )
}

export default Navbar